<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_shipAchieveReport" />
      <v-form ref="form">
        <v-container fluid>
          <!-- 検索項目-->
          <v-row class="search-row">
            <!-- 取引先 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                class="txt-single"
                :rules="[rules.supplierRequired]"
                pa-3
                :hint="setSrhSupplier()"
                persistent-hint
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require">*</span>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷先-->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="shipSelected"
                :items="shipList"
                item-text="displayText"
                item-value="value"
                :label="$t('label.lbl_destination')"
                :hint="setStsArrival()"
                persistent-hint
                @change="changeArrival"
                :search-input.sync="getSuppliersSelected"
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 出荷No-->
            <div class="textbox-arrivalNo">
              <v-text-field
                outlined
                dense
                v-model="txt_shipNo"
                maxlength="50"
                :label="$t('label.lbl_shippingNo')"
                clear-icon="mdi-close-circle"
                clearable
              />
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 承認/未承認-->
            <v-btn-toggle
              mandatory
              v-model="isConfirmed"
              color="blue darken-2"
              id="btn-toggle-btn-padding"
            >
              <v-btn class="paid-btn" value="1" small>
                {{ $t("btn.btn_approval") }}
              </v-btn>
              <v-btn class="paid-btn" value="2" small>
                {{ $t("btn.btn_disapproval") }}
              </v-btn>
            </v-btn-toggle>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- カレンダー -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="arrAchieveDateFrom"
                class="txt-single"
                :label="$t('label.lbl_shipAchieveDate') + '（From）'"
                @change="changeDateFrom"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>

            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenuFrom"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="arrAchieveDateFromCal" @input="dateMenuFrom = false" />
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="preDateOfAchieveFrom" text>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDateOfAchieveFrom" text>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- from toの～ -->
            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="arrAchieveDateTo"
                class="txt-single"
                :label="$t('label.lbl_shipAchieveDate') + '（To）'"
                @change="changeDateTo"
                :rules="[rules.yyyymmdd]"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>

            <div class="calender-btn-area">
              <v-menu
                v-model="dateMenuTo"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="arrAchieveDateToCal" @input="dateMenuTo = false" />
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="preDateOfAchieveTo" text>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDateOfAchieveTo" text>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>

          <!--ボタン領域-->
          <v-row>
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン-->
                <v-btn color="primary" class="api-btn" @click="search(true)">
                  {{ $t("btn.btn_search") }}
                </v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>

              <div class="btn-area">
                <!-- 承認ボタン -->
                <v-btn
                  v-if="isActive == 2 && approveRole == 'true'"
                  @click="confirmDownload"
                  :disabled="ableConfirm"
                  color="primary"
                  class="api-btn"
                >
                  {{ $t("btn.btn_approval") }}
                </v-btn>
                <!-- データ作成ボタン -->
                <v-btn
                  v-if="isActive == 2 && exportRole == 'true'"
                  @click="createData"
                  :disabled="confirmDone"
                  color="primary"
                  class="api-btn"
                >
                  {{ $t("btn.btn_outPutData") }}
                </v-btn>
                <!-- ダウンロードボタン -->
                <v-btn
                  v-if="isActive == 2 && exportRole == 'true'"
                  @click="download"
                  :disabled="createDone"
                  color="primary"
                  class="api-btn"
                >
                  {{ $t("label.lbl_download") }}
                </v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row">
              <!-- 検索結果件数表示部分 -->
              <div class="text-label" style="float: left">
                <div>総件数：{{ totalCount }}件</div>
              </div>

              <!-- 表示件数切替部分 -->
              <div style="float: right">
                <div class="text-label" style="float: left">
                  <span>{{ $t("label.lbl_itemsPerPage") }}</span>
                </div>
                <!-- 空白 -->
                <span class="item-spacer">&nbsp;</span>

                <v-btn-toggle
                  v-model="itemsPerPageBtn"
                  color="blue darken-2"
                  class="toggle black--text"
                  right
                >
                  <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>{{ 10 }}</v-btn>
                  <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>{{
                    50
                  }}</v-btn>
                  <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>{{
                    100
                  }}</v-btn>
                </v-btn-toggle>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル（項目） -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          :class="{ minheight: u_10 }"
          height="600px"
          @page-count="pageCount = $event"
          :options.sync="sortOptions"
        >
          <!-- ロット -->
          <template v-slot:[`header.lot`]="{ header }">
            <span>
              {{ header.text }}<br />
              {{
                $t("label.lbl_dueDate") +
                "／" +
                $t("label.lbl_lotSubkey1") +
                "／" +
                $t("label.lbl_lotSubkey2")
              }}
            </span>
          </template>

          <!-- 伝票No -->
          <template v-slot:[`item.shipNo`]="{ item }">
            <div class="listNo-style">{{ item.shipNo }}</div>
          </template>

          <!-- 商品コード・品名 -->
          <template v-slot:[`item.productCnCd`]="{ item }">
            <div v-if="item.productName.length > 10">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="max-width: 350px" v-bind="attrs" v-on="on">
                    <div>{{ item.productCnCd }}</div>
                    <div>{{ item.incidental }}</div>
                    <div>{{ item.productName }}</div>
                  </div>
                </template>
                <div>{{ item.productCnCd }}</div>
                <div>{{ item.incidental }}</div>
                <div>{{ item.productName }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              <div>{{ item.productCnCd }}</div>
              <div>{{ item.incidental }}</div>
              <div>{{ item.productName }}</div>
            </div>
          </template>
          <!-- ロット/S/N-->
          <template v-slot:[`item.lot`]="{ item }">
            <div v-if="item.lot.length > 15 || item.dueDate.length > 15">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="text-overflow" style="max-width: 300px" v-bind="attrs" v-on="on">
                    {{ item.lot }}<br />
                    {{ item.dueDate }}
                  </div>
                </template>
                <div>{{ item.lot }}<br />{{ item.dueDate }}</div>
              </v-tooltip>
            </div>
            <div v-else>
              <div>{{ item.lot }}<br />{{ item.dueDate }}</div>
            </div>
          </template>
          <!-- * -->
          <!-- 製品付帯 -->
          <template v-slot:[`item.accInfos`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-truncate" style="max-width: 200px" v-bind="attrs" v-on="on">
                  {{ item.accInfos }}
                </div>
              </template>
              <span>{{ item.accInfos }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :screenFlag="infoDialog.screenFlag"
        :screen="infoDialog.changeFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import NavBar from "../../components/NavBar.vue";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import SimpleDialog from "@/components/SimpleDialog";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: appConfig.MENU_ID.P_SHP_ACTUAL_REPORT,
  components: {
    Loading,
    sideMenu,
    NavBar,
    SimpleDialog,
    ConfirmDialog,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_10: false,
    // 承認・未承認
    isConfirmed: "2",
    // 承認ボタン活性化
    ableConfirm: true,
    // データ作成ボタン活性化
    confirmDone: true,
    // ダウンロードボタン活性化
    createDone: true,
    //検索ボタンをクリックした時に挙動を変える
    isActive: 1,
    arrAchieveDateFrom: "",
    arrAchieveDateFromCal: "",
    arrAchieveDateTo: "",
    arrAchieveDateToCal: "",
    // 出荷No
    txt_shipNo: "",
    // 総件数
    totalCount: 0,
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 出荷先
    shipSelected: "",
    // 取引先
    suppliersSelected: "",
    // 取引先SID
    clientSid: "",
    // メニュー
    openMenu: null,
    // カレンダー
    dateMenuTo: false,
    dateMenuFrom: false,
    // メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      screenFlag: false,
      changeFlag: false,
    },
    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    alertMessage: "",
    // 初期データ
    defaultData: [],
    // ヘッダ
    headerItems: [
      // 出荷実績日
      {
        text: i18n.tc("label.lbl_shipAchieveDate"),
        value: "arrivalAcheiveDate",
        width: "5%",
        align: "left",
        sortable: true,
      },
      // 出荷No
      {
        text: i18n.tc("label.lbl_shippingNo"),
        value: "shipNo",
        width: "8%",
        align: "left",
        sortable: true,
      },
      // 商品コード/品名
      {
        text: i18n.tc("label.lbl_productCnCd"),
        value: "productCnCd",
        width: "8%",
        align: "left",
        sortable: true,
      },
      // ステータス
      {
        text: i18n.tc("label.lbl_status"),
        value: "status",
        width: "5%",
        align: "left",
        sortable: true,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lot",
        width: "14%",
        align: "left",
        sortable: true,
      },
      // 出荷実績数
      {
        text: i18n.tc("label.lbl_shipAchieveQty"),
        value: "productQty",
        width: "5%",
        align: "right",
        sortable: true,
      },
      // 付帯情報
      {
        text: i18n.tc("label.lbl_accessoryInfo"),
        value: "accInfos",
        width: "4%",
        align: "left",
        sortable: false,
      },
      // 承認
      {
        text: i18n.tc("btn.btn_approval"),
        value: "approval",
        width: "5%",
        align: "left",
        sortable: false,
      },
    ],
    // ソート
    sortItem: "",
    sortOptions: {},
    // ヘッダここまで
    returnURL: "",
    detailDialogData: {},
    // 項目一覧表示用
    inputList: [],
    // 全量取得
    inputListAll: [],
    // 取引先一覧
    supplierList: [],
    shipList: [],
    // 承認実行権限
    approveRole: "true",
    // 出力実行権限
    exportRole: "true",
    // バリデーション
    rules: {
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),

      supplierRequired: (value) => !!value || i18n.tc("check.chk_inputSupplier"),
    },
    getSuppliersSelected: "",
    productHintArrival: "",
  }),
  methods: {
    /**
     * 初期データ取得
     */
    getCustomInfo(jstInScheduleDate) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC(jstInScheduleDate)
      );
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // 検索ボタン押下時
    search(searchFlg) {
      this.u_10_check();

      // 再検索した際にボタンを非活性に
      this.ableConfirm = true;
      this.confirmDone = true;
      this.createDone = true;

      // 日付の相関チェック
      if (new Date(this.arrAchieveDateFrom).getTime() > new Date(this.arrAchieveDateTo)) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
      } else {
        if (this.$refs.form.validate()) {
          // ボタン類を表示
          // エラーメッセージを非表示
          this.isActive = 2;
          this.alertMessage = "";

          // 検索と全量取得を行う
          this.getApiSearch(searchFlg);
        }
      }
    },

    /**
     * 検索API（入出荷実績報告GETAPI）
     */
    getApiSearch(searchFlg) {
      if (searchFlg) {
        this.page = 1;
      }
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const config = this.$httpClient.createGetApiRequestConfig();
      // 営業所SID
      config.params.officeSid = sessionStorage.getItem("sales_office_sid");
      // 取引先SID
      config.params.clientSid = this.suppliersSelected;
      this.clientSid = this.suppliersSelected;
      // 日時From
      config.params.dateFrom = dateTimeHelper.convertUTC(this.arrAchieveDateFrom);
      // 日時To
      var changedDate = this.arrAchieveDateTo + " 23:59:59.999";
      config.params.dateTo = dateTimeHelper.convertUTC(changedDate);
      // 工程コード
      config.params.procCd = "500";
      // タイムゾーン
      config.params.timeZone = dateTimeHelper.getTimeZone();
      // ページャー処理
      if (this.page >= 2 && this.inputList.length == 0) {
        config.params.reqComPageIndex = "1";
        this.page = 1;
      } else {
        config.params.reqComPageIndex = this.page;
      }
      // ページング
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageLimit = this.itemsPerPage;
      // ソート
      if (this.sortItem != "") {
        config.params.reqComSortItem = this.sortItem;
      }
      if (this.txt_shipNo) {
        // 出荷Noを渡す
        config.params.outListNo = this.txt_shipNo;
      }

      if (this.shipSelected) {
        // 出荷先
        config.params.toSid = this.shipSelected;
      }

      // 承認・未承認どちらが選択されているか
      if (this.isConfirmed == "1") {
        // config.params.approvalFlg = "1";
        config.params.clientRecSendDataFlg = "1";
      } else {
        // config.params.approvalFlg = "0";
        config.params.clientRecSendDataFlg = "0";
      }

      // ソート
      if (this.sortItem != "") {
        config.params.reqComSortItem = this.sortItem;
      }

      // console.debug("getApiSearch(searchFlg) Config", config);

      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.BIZ_ACTUALREPORT, config)
          .then((response) => {
            // console.debug("getApiSearch(searchFlg) Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 成功
            if (jsonData.resCom.resComCode == "000") {
              this.pageCount = jsonData.resCom.resComPagination.totalPage;
              this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              const list = [];
              let approvalFlg = "";

              // 承認件数
              this.actualCnt = jsonData.resIdv.actualCnt;
              // 承認用
              this.opeHists = jsonData.resIdv.opeHists;
              // 出荷実績報告一覧
              jsonData.resIdv.actualReport.forEach((row) => {
                // if (row.approvalFlg == "0") {
                if (row.clientRecSendDataFlg == "0") {
                  approvalFlg = "未承認";
                } else {
                  approvalFlg = "承認済";
                }
                let lotObj = commonUtil.convertLotNo(row.lotNo);
                list.push({
                  productCnCd: row.itemCd.substring(0, 50).trim(), // 商品コード
                  incidental: row.itemCd.slice(-50).trim(), // 付帯品番
                  productName: row.itemName == null ? "" : row.itemName, // 品名
                  productQty: commonUtil.formatToCurrency(Number(row.stock)), //実績数
                  accInfosList: row.outScheduleSub, //付帯情報
                  arrivalAcheiveDate: dateTimeHelper
                    .convertUTC2JST(row.yyyymmddHhmmss)
                    .substr(0, 10), //実績日
                  lot: lotObj.lot, // ロット、S/N
                  dueDate: lotObj.dueDate, // 期限日、付属キー１、付属キー２
                  shipNo: row.outListNo, //出荷no
                  status: row.qualityDivName, //ステータス
                  approval: approvalFlg, //承認未承認
                  outListSubSid: row.outListSubSid,
                  // approvalFlg: row.approvalFlg,
                  approvalFlg: row.clientRecSendDataFlg,
                });
              });
              this.inputList = list;

              var accInfosListBox = [];

              // データがないときは、承認ボタンを非活性
              if (this.inputList.length == 0) {
                this.ableConfirm = true;
              } else {
                // 付帯情報表示編集
                for (var i = 0; i < this.inputList.length; i++) {
                  // 付帯情報がある場合
                  if (this.inputList[i].accInfosList.length > 0) {
                    for (var j = 0; j < this.inputList[i].accInfosList.length; j++) {
                      var setAccInfos = {};
                      setAccInfos =
                        this.inputList[i].accInfosList[j].subInformation +
                        ":" +
                        this.inputList[i].accInfosList[j].subInformationComment;
                      accInfosListBox.push(setAccInfos);
                    }
                    this.inputList[i].accInfos = accInfosListBox.join("、");
                  } else {
                    // 付帯情報がない場合
                    this.inputList[i].accInfos = "";
                  }
                  if (this.inputList[i].approvalFlg == "0") {
                    // 未承認が選択されている場合、ダウンロードボタン非活性/承認ボタン活性
                    this.confirmDone = true;
                    this.ableConfirm = false;
                  } else {
                    // 承認が選択されている場合、ダウンロードボタン活性/承認ボタン非活性
                    this.confirmDone = false;
                    this.ableConfirm = true;
                  }
                }
              }

              resolve(response);
              // 失敗
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
            }
            reject();
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // ページングとテーブルの間隔調整
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.search(false);
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.search(false);
    },
    // 承認ボタンが押されたときの処理
    confirmDownload() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();

      // 共通IF項目 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SHP_002;

      // 工程実績SID一覧
      body.reqIdv.opeHists = this.opeHists;

      // console.debug("confirmDownload() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          .securePost(appConfig.API_URL.BIZ_ACTUALREPORT, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("confirmDownload() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              // 表示の切り替えのために再検索を行う
              this.isConfirmed = "1";
              this.getApiSearch(false);
              this.confirmDone = false;
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            }
            resolve();
          })
          .catch((ex) => {
            console.error("confirmDownload() Resolve", resolve);
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    createData() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      // 共通IF項目 画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_SHP_002;
      // 営業所SID
      body.reqIdv.officeSid = sessionStorage.getItem("sales_office_sid");
      // 取引先SID
      body.reqIdv.clientSid = this.clientSid;
      if (this.shipSelected) {
        // ToSID
        body.reqIdv.toSid = this.shipSelected;
      }
      if (this.txt_shipNo) {
        // 入荷伝票No
        body.reqIdv.outListNo = this.txt_shipNo;
      }
      // 実績日
      body.reqIdv.dateFrom = dateTimeHelper.convertUTC(this.arrAchieveDateFrom);
      var arr = this.arrAchieveDateTo + " 23:59:59.999";
      body.reqIdv.dateTo = dateTimeHelper.convertUTC(arr);
      // 工程コード
      body.reqIdv.procCd = "500";
      body.reqIdv.timeZone = dateTimeHelper.getTimeZone();
      // console.debug("createData() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          .securePost(appConfig.API_URL.BIZ_ACTUALSFILE, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("createData() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              // 返却されたurlをデータに格納、ダウンロードボタンの活性化
              this.returnURL = jsonData.resIdv.filePath;
              location.href = this.returnURL;
              // Promise.all([this.search()])
              //   .then(() => {
              //     this.createDone = true;
              //   })
              //   .catch(() => {});
            } else {
              // エラーメッセージをpopupのmessageに格納
              let message = jsonData.resCom.resComMessageId
                ? messsageUtil.getMessage(jsonData.resCom.resComMessageId)
                : jsonData.resCom.resComMessages["resComMessage" + sessionStorage.getItem("lang")];
              this.infoDialog.message = message ? message : jsonData.resCom.resComMessage;
              this.infoDialog.title = "結果";
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
            }
            resolve(response);
          })
          .catch((resolve) => {
            console.error("createData() Resolve", resolve);
            this.infoDialog.message = messsageUtil.getMessage("P-999-999_999_E");
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            reject(resolve);
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    // ダウンロードボタンが押された時の処理
    download() {
      location.href = this.returnURL;
    },
    // 初期化
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);

      this.shipSelected = "";
      this.suppliersSelected = "";
      this.txt_shipNo = "";
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";
      this.inputList = [];
      // 初期表示はconvertJST
      this.recieveDateCal = dateTimeHelper.convertJST(new Date());
      this.arrAchieveDateFrom = dateTimeHelper.convertJST(new Date());
      this.arrAchieveDateTo = dateTimeHelper.convertJST(new Date());
      this.arrAchieveDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST(new Date()));
      this.arrAchieveDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST(new Date()));
      this.getCustomInfo();

      // 荷札発行権限を取得
      this.approveRole = sessionStorage.getItem("P-SHP-009_APPROVE");
      this.exportRole = sessionStorage.getItem("P-SHP-009_EXPORT");
    },

    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    // 入荷実績日付カレンダーFrom
    preDateOfAchieveFrom() {
      if (this.arrAchieveDateFromCal == null) {
        return;
      }
      let date = new Date(this.arrAchieveDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.arrAchieveDateFromCal != toDate) {
        this.arrAchieveDateFromCal = toDate;
      } else {
        this.arrAchieveDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },
    nextDateOfAchieveFrom() {
      if (this.arrAchieveDateFromCal == null) {
        return;
      }
      let date = new Date(this.arrAchieveDateFromCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.arrAchieveDateFromCal != toDate) {
        this.arrAchieveDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.arrAchieveDateFromCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    // 入荷実績日付カレンダーto
    preDateOfAchieveTo() {
      if (this.arrAchieveDateToCal == null) {
        return;
      }
      let date = new Date(this.arrAchieveDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.arrAchieveDateToCal != toDate) {
        this.arrAchieveDateToCal = toDate;
      } else {
        this.arrAchieveDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },
    nextDateOfAchieveTo() {
      if (this.arrAchieveDateToCal == null) {
        return;
      }
      let date = new Date(this.arrAchieveDateToCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.arrAchieveDateToCal != toDate) {
        this.arrAchieveDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.arrAchieveDateToCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    // カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },
    /**
     * 日付入力(From)
     */
    changeDateFrom(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.arrAchieveDateFromCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.arrAchieveDateFromCal = null;
      }
    },

    /**
     * 日付入力(To)
     */
    changeDateTo(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.arrAchieveDateToCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.arrAchieveDateToCal = null;
      }
    },
    /**
     * 取引先コードリストボックスセット処理
     */
    setSrhSupplier() {
      for (var i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },
    setStsArrival() {
      for (var i = 0; i < this.shipList.length; i++) {
        if (this.shipList[i].value == this.shipSelected) {
          return this.shipList[i].name;
        }
      }
    },
    /**
     * プルダウンチェンジイベント
     */
    changeArrival(value) {
      // 既存品番/品名を選択時
      let val = this.shipList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintArrival = val.displayText;
        this.getSuppliersSelected = val.displayText;
      }
      this.shipList = this.shipList.filter((v) => v.value == value);

      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintArrival = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 変更検知用の設定
      this.arrAchieveDateFromCal = "";
      this.arrAchieveDateToCal = "";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },
  computed: {},
  watch: {
    //取引先変更時
    suppliersSelected() {
      //出荷先リストクリア
      this.shipSelected = "";
      this.shipList = [];
    },
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    arrAchieveDateFromCal: {
      handler(val) {
        this.arrAchieveDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    arrAchieveDateToCal: {
      handler(val) {
        this.arrAchieveDateTo = this.formatDate(val);
      },
      deep: true,
    },
    page: function (newValue) {
      this.page = newValue;
      this.search(false);
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_SHP_009_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.search(false);
        }
      },
      deep: true,
    },
    // 出荷先取得
    getSuppliersSelected(val) {
      if (val ?? false) {
        if (this.suppliersSelected == null || this.suppliersSelected == "") {
          this.shipList = [];
          this.shipSelected = "";
          return;
        }
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintArrival) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        // 入力桁数が定義した数異常の場合検索処理
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 選択した会社コードを取得
          let officeCd = this.supplierList.find(
            (element) => element.value === this.suppliersSelected
          ).clientSid;
          // To（出荷先）取得
          const fromList = getParameter.getRelatedBizPartialMatch(
            appConfig.ISGETCLIENT.FROMTO,
            dateTimeHelper.convertUTC(),
            undefined,
            officeCd,
            val
          );
          Promise.all([fromList])
            .then((result) => {
              // 画面の初期値を設定します。
              this.shipList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.shipList.length = 0;
          this.shipList = [];
        }
      }
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.toatlNum {
  margin-left: 10px;
}

.text-overflow {
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
